.bar-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px; /* Adjust as needed */
    width: 100%; /* Adjust as needed */
}
  
.bar {
    width: 1px; /* Adjust as needed */
    background-color: #D9D9D9; /* Adjust as needed */
}
  