#threshold-box {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.label-font {
    color: #737373;
    font-family: 'Roboto Mono';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#lines-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 20px;
    box-sizing: border-box;
}
  
#center-line {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #E4E4E4;
    top: 0px; /* To start at the center of the container */
}

#dotted-line {
    width: 100%;
    position: relative;
    border-bottom: 1px dotted black;
}

#label-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 1px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: top 0.3s;
}