html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  align-content: flex-start;
  background-color: green;
}

/* Font styles */

h1 {
  color: #000;
  font-family: 'Roboto Mono';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  width: 100%;
}

b {
  color: #000;
  font-family: 'Roboto Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.b1 {
  color: #000;
  font-family: 'Roboto Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.b2 {
  color: #000;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-style: medium;
  font-weight: 400;
  line-height: normal;
}

.status {
  font-family: 'Roboto Mono';
  color: #FF8080;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.data {
  color: #000;
  font-family: 'Roboto Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p {
  color: #6C6C6C;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

/* Layouts */

main {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background-color: bisque;
}

#control-panel {
  display: flex;
  width: 300px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  flex-shrink: 0;
  background-color: white;
  border-left: 1px solid #D9D9D9;
  padding: 0;
  margin: 0;
}

header {
  display: flex;
  min-height: 46.5px;
  padding-left: 20px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: white;
  border-bottom: 1px solid #D9D9D9;
}

#stats-bar {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  background-color: white;
  border-bottom: 1px solid #D9D9D9;
}

#visualization-container {
  display: flex;
  position: relative;
  min-height: 250px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: white;
  border-bottom: 1px solid #D9D9D9;
}

#personas-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  overflow: hidden;
}

#persona {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Changed from max-height to height */
  padding: 20px 20px 0px 20px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  background-color: white;
  border-right: 1px solid #D9D9D9;
  gap: 30px;
  box-sizing: border-box;
}

#persona-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1; /* Allows it to take up all available space */
  gap: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 30px;
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent transparent; /* Make it invisible for Firefox */
}

/* Chrome, Safari, and other Webkit browsers */
#persona-content::-webkit-scrollbar {
  width: 0; /* Set width to 0 to make it disappear */
}

#persona-stats {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 30px;
}

#instability {
  display: flex;
  flex-direction: column;
  width: 80px;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  padding: 0;
}

#bar-chart {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  height: 100%;
  width: 88%;
  margin-left: 8%; /* Centers the container */
  gap: 2px; /* Adds space between bars */
  z-index: 1;
}

#bar {
  width: 4px;
  background-color: #D9D9D9;
}

#threshold-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: aliceblue;
}

#dotted-line {
  position: relative;
  width: 100%;
  border-bottom: 1px dotted black;
  top: 50%; /* To start at the center of the container */
  transform: translateY(-20%);
}

#tabs {
  display: flex;
  max-height: 46.5px;
  padding: 0;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid #D9D9D9;
}

#tab {
  display: flex;
  height: 40.5px;
  justify-content: center;
  align-items: center;
  gap: 0px;
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;
  padding: 0;
  margin-top: 3px;
}

.tab-selected {
  border-bottom: 4px solid black;
  color: black;
}

.tab-unselected {
  color: #D9D9D9;
}

#config-content {
  display: flex;
  max-height: 100%;
  padding: 30px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: auto;
  padding-bottom: 40px;
}

.button1 {
  display: flex;
  width: 100%;
  max-width: 300px;
  height: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #000;
  border: 1px solid #000;
  color: #FFF;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.button2 {
  display: flex;
  width: 140px;
  height: 30px;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  background: #F2F2F2;
  color: #000;
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

#status-bar {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: rgba(255, 128, 128, 0.10);
}

#config-stat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

#config-stat-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}