#player-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 42px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;  /* added this line */
}

#button-container {
    height: 24px;
    width: 24px;
    cursor: pointer;
}