#container {
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    align-self: stretch;
}

#info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 6px;
}

#text {
    font-family: 'Roboto Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

  
  input[type="range"] {
    width: 100%; /* Make the slider take the full width of its container */
    height: 2px;
    background-color: #E4E4E4; /* Background color of the slider */
    border-radius: 0px; /* Rounded corners for the slider */
    -webkit-appearance: none; /* Remove default browser styles */
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default browser styles */
    width: 16px; /* Width of the thumb */
    height: 16px; /* Height of the thumb */
    background-color: #FFF;
    border: 2px solid #A7A7A7;
    border-radius: 50%; /* Make the thumb circular */
    cursor: pointer; /* Change cursor to pointer while hovering over the thumb */
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 16px; /* Width of the thumb */
    height: 16px; /* Height of the thumb */
    background-color: #000; /* Background color of the thumb */
    border-radius: 50%; /* Make the thumb circular */
    cursor: pointer; /* Change cursor to pointer while hovering over the thumb */
  }
