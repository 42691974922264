.modal-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .modal-background.open {
    display: flex;
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 0px;
    width: 300px;
    gap: 30px;
    z-index: 3;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .close-button:focus {
    outline: none;
  }

  #title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }


  