.dropdown {
    position: relative;
    width: 100%;
    max-width: 300px;
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-label {
    margin-bottom: 8px;
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000;
    padding: 0;
    margin-bottom: 6px;
  }
  
  .dropdown-control {
    position: relative;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -1px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    opacity: 0;
    transition: opacity 0.3s ease;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }
  
  .dropdown-menu.open {
    opacity: 1;
    visibility: visible;
  }
  
  .dropdown-option {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }

  .dropdown-marker {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #292929;
  }

  .dropdown-header {
    position: relative;
    padding: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-arrow {
    position: absolute;
    right: 8px;
    width: 18px;  /* Adjust as necessary */
    height: auto;
  }

  .dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  
  