/* TextBlock.css */
.text-block {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 100;
    -webkit-box-orient: vertical;
  }
  
  .text-block.truncated {
    -webkit-line-clamp: 4;
  }
