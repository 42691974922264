.text-input {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
  
  .text-input-label {
    margin-bottom: 8px;
    font-size: 12px;
    color: #000;
  }
  
  .text-input-field {
    Font-family: 'Roboto Mono', monospace;
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 0px;
    font-size: 14px;
    outline: none; /* Removes the browser default focus outline */
    transition: border-color 0.3s; /* Adds a transition effect when the border color changes */
    resize: none;
  }
  
  .text-input-field:focus {
    border-color: #666; /* Changes the border color when the input field is focused */
    border-radius: 0px;
  }
  
  .text-input-field::placeholder {
    color: #aaa; /* Styles the placeholder text color */
  }
  