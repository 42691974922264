.stat {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
}

.stat-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
}

.stat-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.stat-number {
    font-family: 'Roboto Mono';
    font-size: 16px;
    font-weight: normal;
}

.stat-label {
    font-family: 'Roboto Mono';
    font-size: 12px;
    font-weight: normal;
}
